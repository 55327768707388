import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Grid, Col, Row } from 'react-styled-flexboxgrid'
import ImgSet from '../components/imgset'
import License from '../docs/LICENSE.pdf'
import Specimen from '../docs/megalove-type-specimen.pdf'

const IndexPage = () => {

  return (
    <Layout>
      <SEO title="Megalove" />
      <div className="about">
        <Grid>
          <Row center="xs">
            <Col md={8} sm={10} xs={12}>
              <div className="about__text">
                Say aloha to Megalove! The large and bold ornamental A to Z alphabet designed for drop cap letters, editorial design, initials, large signs or wherever it would fit. Includes some diacritics for Western European languages and it comes in three different styles so it can be used as a layer font.
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
      <ImgSet />
      <div className="about">
        <Grid>
          <Row center="xs">
            <Col md={8} sm={10} xs={12}>
              <div className="about__text">
                By licensing Megalove you'll get the three styles (regular, solid and shadow) in desktop format (otf) and webfont format (eot, woff and woff2). Here are some useful links about <a href="https://glyphsapp.com/tutorials/creating-a-layered-color-font">creating and using layered fonts</a> in design programs or <a href="https://css-tricks.com/snippets/css/drop-caps/">drop caps</a> with CSS in web design. Please, read the <a href={License}>license</a> before purchasing or download the <a href={Specimen}>type specimen</a>.
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
      <div className="buy">
        <a class="buy__link" href="https://fonts.rubenprol.gal/b/megalove">License Megalove</a>
      </div>
      <div className="thank-you">
        <div className="thank-you__m">M</div>
        <div className="thank-you__text">This font is named after my friend Esteban in memoriam.</div>
      </div>
    </Layout>
  )
}

export default IndexPage
